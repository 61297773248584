import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { XIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'
import { ITopBanner } from 'features/common/types'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { FC, useCallback, useEffect } from 'react'
import Slider, { Settings } from 'react-slick'

import styles from './HeaderTopBanner.module.scss'
interface ITopBannerProps {
  isExternalUrl?: boolean
  bannerData: ITopBanner[] | undefined
  isDialogOpened: boolean
  onDialogOpenHandler: () => void
  onDialogCloseHandler: () => void
}

interface ITopBannerItemProps {
  isExternalUrl?: boolean
  data: ITopBanner
  onDialogCloseHandler: () => void
}

const HeaderTopBanner: FC<ITopBannerProps> = ({
  isExternalUrl = false,
  bannerData,
  isDialogOpened,
  onDialogOpenHandler,
  onDialogCloseHandler,
}) => {
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  }
  const hideUntil = localStorage.getItem('hideBanner')

  useEffect(() => {
    if (hideUntil && dayjs().isBefore(Number(hideUntil))) {
      onDialogCloseHandler()
    } else onDialogOpenHandler()
  }, [hideUntil])

  return (
    <>
      {bannerData && (
        <div className={`w-full ${isDialogOpened ? 'block' : 'hidden'}`}>
          <Slider {...settings}>
            {bannerData.map((data, idx) => (
              <HeaderTopBannerItem
                key={`header_banner_${idx}`}
                data={data}
                isExternalUrl={isExternalUrl}
                onDialogCloseHandler={onDialogCloseHandler}
              />
            ))}
          </Slider>
        </div>
      )}
    </>
  )
}

const HeaderTopBannerItem = ({
  isExternalUrl = false,
  data,
  onDialogCloseHandler,
}: ITopBannerItemProps) => {
  const router = useRouter()
  const { redirectionPath, backgroundColor, textColor, bannerDescription, imgSrc } = data
  const hideUntilMidnight = dayjs().endOf('day').valueOf()

  const goToUrl = useCallback(() => {
    return isExternalUrl ? window.open(redirectionPath) : router.push(redirectionPath)
  }, [router])

  const onClose = useCallback(e => {
    e.stopPropagation()
    onDialogCloseHandler()
    localStorage.setItem('hideBanner', hideUntilMidnight.toString())
  }, [])

  return (
    <div
      className="w-full cursor-pointer overflow-hidden bg-yellow-400"
      onClick={goToUrl}
      style={{ backgroundColor: backgroundColor }}>
      <div
        className={`mx-auto flex max-w-7xl items-center justify-between overflow-hidden px-4 py-2 md:py-2.5 lg:px-6 ${styles.TopBannerWrap}`}>
        <div className="flex items-center gap-2">
          <p
            className="flex flex-row items-center gap-x-2 whitespace-nowrap text-sm font-medium md:text-base md:font-semibold"
            style={{ color: textColor }}>
            {bannerDescription}
          </p>
          <Image
            src={imgSrc}
            alt="topBanner"
            className="block h-auto w-[52px] object-cover"
            width={52}
            height={46}
            layout="fixed"
          />
        </div>
        <div
          className="z-10 inline-flex h-5 w-5 cursor-pointer items-center justify-center"
          style={{ color: textColor }}
          onClick={onClose}>
          <XIcon className="h-full w-full" />
        </div>
      </div>
    </div>
  )
}

export default HeaderTopBanner
