import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { convertCaseList, ConvertType } from 'utils/convertString'

import { ITopBanner, ITopBannerResponse } from '../types'

const fetchTopBanner = async () => {
  const resp = await axios.get<IListDataResp<ITopBannerResponse>>(
    `${window.apiEndpoint}/showroom/v1/header-banners?filters=displayed_service:likelion|status:ing`,
  )
  return convertCaseList(resp.data, ConvertType.CAMEL)
}

export const useTopBanner = () => {
  return useQuery<IListDataConvertResp<ITopBanner>>({
    queryKey: ['topBanner'],
    queryFn: () => fetchTopBanner(),
  })
}
