export const phoneNoReg = /^[0-9]{11}$/
export const emailReg = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const BLOCK_KLASS_LIST = [
  'gitblog',
  'style_guide',
  'pp_package',
  'pp_basic',
  'pp_adv',
  'uxd',
]

export const TOP_BANNER_HIDE_LIST = [
  '/oauth',
  '/my/courses/detail/[schoolId]',
  '/my/payments',
  '/my/coupons',
  '/my/profile',
  '/my/change-password',
  '/my/change-temp-password',
  '/courses/technology/[id]/[chapterId]/[resourceId]',
  '/courses/technology/[id]/[chapterId]/[resourceId]/[attrId]',
]
